/**
 *
 * Informar strlink para definir o src do iframe, ou
 * Informar form para fazer submit com target=iframe
 *
 * @param strLink
 * @param largura
 * @param altura
 * @param exibeFullScreen
 * @param form
 * @param parentContainerId string da ID do elemento que contém o subform. Se undefined, document.body é usado
 * @param modalAttrs object  => {} por default
 * @param backgroundAttrs object => {} por default
 * @param autofocus boolean => default: true - Ao carregar o subform, por padrão há tentativa de autofocus no primeiro elemento com tabindex. Use false para desabilitar esse comportamento.
 */
export default function exibirSubFrm(
    strLink,
    largura,
    altura,
    exibeFullScreen,
    form,
    parentContainerId,
    modalAttrs,
    backgroundAttrs,
    autofocus,
) {
    sinalizaEventoOpenSubform();

    const parentContainerEl = parentContainerId ? document.getElementById(parentContainerId) : document.body;
    const modalStyles = modalAttrs ? modalAttrs : {};
    const backgroundStyles = backgroundAttrs ? backgroundAttrs : {};
    autofocus = (autofocus === undefined ? true : autofocus)
    exibeFullScreen = (exibeFullScreen === undefined ? false : exibeFullScreen)
    altura = getAltura(altura, exibeFullScreen)
    largura = normalizeDimension(largura)

    //esconde div das abas, se existe
    $('.ui-tabs-nav', top.document).hide();
    $('[id^=tabs-]', parent.window.document).height(
        $('#divInfraAreaDados', parent.window.document).height(),
    );

    const divFundo = createOverlay(backgroundStyles);
    const ifrSub = createIframe(exibeFullScreen, largura, altura, modalStyles);
    divFundo.appendChild(ifrSub);
    parentContainerEl.appendChild(divFundo);
    parentContainerEl.style.overflowY = 'hidden';

    if (autofocus) {
        ifrSub.focus()
    }

    if (strLink) {
        ifrSub.src = strLink;
    } else if (typeof (form) == 'object') {
        form.target = ifrSub.name;
        form.submit();
    }
}

function getAltura(altura, exibeFullScreen) {
    if (!exibeFullScreen && altura == null) {
        if (retVar('acao') == 'sessao_julgamento_exibir_painel'
            || retVar('acao_origem') == 'sessao_julgamento_exibir_painel'
        ) {
            altura = 0.9 * $('#divInfraAreaDados', parent.window.document).height();
        } else {
            altura = 0.72 * screen.height;
        }

        return altura += 'px';
    } else {
        return normalizeDimension(altura)
    }
}

function createOverlay(backgroundStyles) {
    let divFundo
    try {
        divFundo = document.getElementById('divInfraAvisoFundo');
        if (divFundo) {
            document.body.removeChild(divFundo);
            divFundo = null;
        }
    } catch (e) {
        if (typeof jQuery === 'undefined') {
            console.log(e);
        } else {
            document.getElementById('divInfraAvisoFundo').remove()
        }
    }

    divFundo = document.createElement('div');
    divFundo.id = 'divInfraAvisoFundo';
    divFundo.className = 'infraFundoTransparente';

    Object.keys(backgroundStyles).forEach(property => divFundo.style[property] = backgroundStyles[property])

    return divFundo
}

function createIframe(exibeFullScreen, largura, altura, modalStyles) {
    const ifrSub = document.createElement('iframe');
    ifrSub.id = 'ifrSubFrm';
    ifrSub.name = 'ifrSubFrm';
    ifrSub.classList.toggle('is-fullscreen', exibeFullScreen)
    ifrSub.style.width = largura
    ifrSub.style.height = altura

    Object.keys(modalStyles).forEach(property => ifrSub.style[property] = modalStyles[property])
    ifrSub.onload = onIframeLoad
    return ifrSub
}

function onIframeLoad() {
    const jqIframe = $('#ifrSubFrm')
    $(jqIframe.contents().find('button')[0]).focus()

    var s = document.createElement('script');
    s.textContent = `
        window.onkeydown = function( event ) {
            if ( event.keyCode == 27 ) {
                ocultarSubFrm();
            }
        };`;
    jqIframe.contents().find('body').append(s);

    //todo dni13 - body do iframe nao preenche todo o html do subform - solucao de contorno
    jqIframe.contents().find('body').css('height', '100vh');
}

const normalizeDimension = (value) => {
    if (value === undefined || value === null) {
        return '100%';
    } else if (!isNaN(value)) {
        return value + 'px';
    } else {
        return value;
    }
}

function sinalizaEventoOpenSubform() {
    document.body.classList.add('has-open-subfrm');
    if (typeof window.parent.EventoOpenSubfrm == 'function') {
        window.parent.EventoOpenSubfrm();
    }
}
