'use strict'
import toast from '#eproc/toast'

(
    function(window, $) {

        // todo: abrir issue pra isso
        // if (LOGAR_ERROS_JS || ENV === 'dev') {
        if (ENV === 'dev') {
            window.onerror = (msg, url, line, col, error) => {
                infraOcultarAviso(false)
                const formattedErrorMessage = buildErrorMessage(msg, url, line, col, error)
                displayErrorMessage(formattedErrorMessage)
                log(formattedErrorMessage)

                return false
            }
        }

        function log(erro) {
            $.ajax({
                url: $('input[name=url_log_erro_javascript]').val(),
                type: 'POST',
                eprocAlertarErro: true,
                data: {
                    msgErro: erro,
                },
            })
        }

        function buildErrorMessage(msg, url, line, col, error) {
            return msg
                + '\nUrl: ' + url
                + '\nLine: ' + line
                + (
                    col ? (
                        '\nColumn: ' + col
                    ) : ''
                )
                + (
                    error ? (
                        '\nError: ' + error
                    ) : ''
                )
        }

        function displayErrorMessage(message) {
            toast.error({
                title: 'Uncaught Error',
                text: `<pre style="max-width: 310px;"><code class="w-100">${message}</code></pre>`,
            })
        }
    }
)(window, $ || jQuery)
