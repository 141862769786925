require('abortcontroller-polyfill/dist/polyfill-patch-fetch');

String.prototype.lpad = function (pSize, pCharPad) {
    var str = this;
    var dif = pSize - str.length;
    var ch = String(pCharPad).charAt(0);
    for (; dif > 0; dif--) str = ch + str;
    return (str);
}
if (!String.prototype.trim) {
    String.prototype.trim = function () {
        var ret = null;
        ret = this.replace(/^\s*/, "").replace(/\s*$/, "");
        ret = (this == 0 ? "" : this);
        return ret;
    }
}
String.prototype.toFormatDate = function (oldFormat, newFormat) {
    var ano = mes = dia = newDate = null;
    var iD = iM = iA = 0;

    for (var i = 0; i < oldFormat.length; i++) {
        switch (oldFormat[i]) {
            case 'd':
                dia = (dia == null ? this[i] : dia += this[i]);
                break;
            case 'm':
                mes = (mes == null ? this[i] : mes += this[i]);
                break;
            case 'a':
                ano = (ano == null ? this[i] : ano += this[i]);
                break;
        }
    }

    for (var i = 0; i < newFormat.length; i++) {
        switch (newFormat[i]) {
            case 'd':
                newDate = (newDate == null ? dia[iD] : newDate += dia[iD]);
                iD++;
                break;
            case 'm':
                newDate = (newDate == null ? mes[iM] : newDate += mes[iM]);
                iM++;
                break;
            case 'a':
                newDate = (newDate == null ? ano[iA] : newDate += ano[iA]);
                iA++;
                break;
            default:
                newDate = (newDate == null ? newFormat[i] : newDate += newFormat[i]);
        }
    }

    return newDate.trim();
}
addEvent = function (o, e, f, s) {
    var r = o[r = "_" + (e = "on" + e)] = o[r] || (o[e] ? [[o[e], o]] : []), a, c, d;
    r[r.length] = [f, s || o], o[e] = function (e) {
        try {
            (e = e || event).preventDefault || (e.preventDefault = function () {
                e.returnValue = false;
            });
            e.stopPropagation || (e.stopPropagation = function () {
                e.cancelBubble = true;
            });
            e.target || (e.target = e.srcElement || null);
            e.key = (e.which + 1 || e.keyCode + 1) - 1 || 0;
        } catch (f) {
        }
        for (d = 1, f = r.length; f; r[--f] && (a = r[f][0], o = r[f][1], a.call ? c = a.call(o, e) : (o._ = a, c = o._(e), o._ = null), d &= c !== false)) ;
        return e = null, !!d;
    }
};

removeEvent = function (o, e, f, s) {
    for (var i = (e = o["_on" + e] || []).length; i;)
        if (e[--i] && e[i][0] == f && (s || o) == e[i][1])
            return delete e[i];
    return false;
};

if (!Date.now) {
    Date.now = function now() {
        return (new Date()).getTime();
    }
}

if (!('localStorage' in window)) {
    window.localStorage = require('local-storage-fallback');
}