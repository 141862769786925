$(function () {
    $(document).on('click', '.ajax-tab-refresh-button', function () {
        const tab = $(this).parent()
        const panel = getPanel(tab)
        showAjaxTabPanel(panel, true);
    })

    $(document).on('show.bs.tab', '.nav-tabs a', function () {
        const activePanel = $('.tab-pane.active')

        if (activePanel.length) {
            showAjaxTabPanel(activePanel);
        }
    });

    $(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {
        let panel = getPanel($(this))
        showAjaxTabPanel(panel);
    });

    function getPanel(tab) {
        return $('#' + tab.attr('href').substr(1));
    }

    function showAjaxTabPanel(tab, forceReload) {
        const url = tab.attr('data-target')
        if (url && (forceReload || !tab.html())) {
            eproc.ajax.load(tab, url, {})
        }
    }
})
